import React, { useContext, useEffect, useState } from "react";
import { RespondentHomepageContext } from "../../providers";
import { Link, useNavigate, useSearchParams } from "react-router";
import { AlertBox, getErrorMessage } from "@careerinsight/applib-react";
import { RaterSurveyInstanceSummary, RespondentHomepageResponse } from "@careerinsight/applib-common/entity";

type FormData = {
  message: string;
};

export const SendReminder: React.FC = ({}) => {
  const context = useContext(RespondentHomepageContext);
  const [searchParams] = useSearchParams();

  const [formData, setFormData] = useState<FormData>({ message: "" });
  const [validationErrors, setValidationErrors] = useState<FormData>({ message: "" });
  const [submitted, setSubmitted] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const model = context.model!;
  const surveyInstanceSecretKey = searchParams.get("k")!;
  const raterSurveyUuid = searchParams.get("s");
  if (!raterSurveyUuid) return <pre>Rater survey ID is missing</pre>;

  // Retrieve the raterSurveyInstance with matching raterSurveyUuid
  const raterSurveyInstance = model.raterSurveyInstances!.find((s) => s.surveyInstanceUuid === raterSurveyUuid);
  if (!raterSurveyInstance) return <pre>Rater survey not found</pre>;

  // Set the default message
  useEffect(() => setFormData({ message: getDefaultMessage(model, raterSurveyInstance) }), [raterSurveyInstance]);

  const onChange: React.ChangeEventHandler<HTMLTextAreaElement> = (event) => {
    const { name, value } = event.target;
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
    if (submitted) validate(newFormData);
  };

  const validate = (formData: FormData) => {
    const newErrors = { message: "" };
    if (!formData.message) newErrors.message = "Please enter a message";
    setValidationErrors(newErrors);
    return newErrors;
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    setSubmitted(true);
    event.preventDefault();
    const errors = validate(formData);
    if (errors.message) return;

    setIsLoading(true);
    const response = await context.sendReminderMessage({ ...formData, raterSurveyUuid });

    if (response.status !== 200) {
      const content = await response.json();
      setServerErrorMessage(getErrorMessage(content));
      setIsLoading(false);
    } else {
      await navigate("/homepage?k=" + surveyInstanceSecretKey);
      setIsLoading(false);
    }
  };

  return (
    <>
      <h1 className="text-3xl font-bold">Send a personal reminder message</h1>
      <p>An email with a link to the feedback survey will be sent to {raterSurveyInstance.forename}.</p>
      <p>Please customise the email message in the text area below.</p>
      {serverErrorMessage && <AlertBox>{serverErrorMessage}</AlertBox>}

      <form
        className="bg-white ring-1 shadow-xs ring-secondary-border rounded-lg md:col-span-2"
        onSubmit={onSubmit}
        noValidate={true}
      >
        <div className="px-4 py-6 sm:p-8">
          <label htmlFor="message" className="block text-sm/6 font-medium text-dark">
            Message
          </label>

          <div className="mt-2">
            <textarea
              name="message"
              id="message"
              rows={12}
              maxLength={4096}
              className={`block w-full rounded-md bg-white px-3 py-1.5 border focus:outline-2 focus:outline-primary disabled:bg-secondary-light disabled:text-secondary ${
                validationErrors.message ? "border-danger text-danger" : "bg-secondary-light/20 border-secondary-border"
              }`}
              value={formData.message}
              onChange={onChange}
              disabled={isLoading}
            />
            {validationErrors.message && (
              <small className="text-danger font-semibold">{validationErrors.message}</small>
            )}
          </div>
        </div>

        <div className="flex items-center gap-x-6 border-t border-secondary-border px-4 py-4 sm:px-8">
          <button type="submit" className="btn btn-primary relative" disabled={isLoading}>
            <div className={`w-full absolute left-0 flex justify-center ${isLoading ? "visible" : "invisible"}`}>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="h-6 animate-spin">
                <g fill="currentColor">
                  <circle cx="12" cy="2.5" r="1.5" opacity=".14" />
                  <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                  <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                  <circle cx="21.50" cy="12.00" r="1.5" opacity=".57" />
                  <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                  <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                  <circle cx="12" cy="21.5" r="1.5" />
                </g>
              </svg>
            </div>

            <div className={`flex items-center gap-2 ${isLoading ? "invisible" : "visible"}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 28 28" width={22} height={22}>
                <path
                  fill="currentColor"
                  d="M 3.59375 5.34375 L 4.03125 7.21875 L 5.96875 16 L 4.03125 24.78125 L 3.59375 26.65625 L 5.375 25.9375 L 27.375 16.9375 L 29.65625 16 L 27.375 15.0625 L 5.375 6.0625 L 3.59375 5.34375 z M 6.375 8.65625 L 21.90625 15 L 7.78125 15 L 6.375 8.65625 z M 7.78125 17 L 21.90625 17 L 6.375 23.34375 L 7.78125 17 z"
                ></path>
              </svg>

              <div>Send reminder</div>
            </div>
          </button>

          <Link
            to={{ pathname: "/homepage", search: "?k=" + surveyInstanceSecretKey }}
            className={`btn btn-secondary ${isLoading ? "disabled" : ""}`}
          >
            Cancel
          </Link>
        </div>
      </form>
    </>
  );
};

export default SendReminder;

function getDefaultMessage(model: RespondentHomepageResponse, raterSurveyInstance: RaterSurveyInstanceSummary) {
  return `Hi ${raterSurveyInstance.forename},

I recently nominated you to provide feedback on my leadership impact as part of a 360° assessment and can see that you haven’t yet completed your questionnaire.  Your feedback is very valuable to me as it will help me to inform my future development, and I don’t want to miss this opportunity to capture your views.

This is a time-sensitive task, and the deadline is rapidly approaching, so I would really appreciate it if you could complete the questionnaire at your earliest opportunity.

Many thanks, ${model.forename}`;
}
