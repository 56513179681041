import React, { useContext } from "react";
import { YourSurveyCard } from "../YourSurveyCard";
import { RespondentHomepageContext } from "../../providers";
import { RaterProgressCard } from "../RaterProgressCard";

export const Homepage: React.FC = ({}) => {
  const props = useContext(RespondentHomepageContext);
  const model = props.model!;

  return (
    <>
      <h1 className="text-3xl font-bold pb-4">
        {model.surveyProject.surveyProjectLabel} for {model.forename} {model.surname}
      </h1>

      <p className="text-xl pb-6">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nisi reprehenderit sapiente vero.
      </p>

      <div className="grid grid-cols-1 gap-8 md:max-w-4xl md:grid-cols-2">
        <YourSurveyCard model={model} />
      </div>

      {model.surveyProject.is360 && <RaterProgressCard model={model} />}
    </>
  );
};

export default Homepage;
