import React from "react";
import { SurveyStatus } from "@careerinsight/applib-common/entity";
import { Link, useSearchParams } from "react-router";
import Badge from "../Badge/Badge";
import { RequiredRaterType, StatusColor } from "../../types";

export const RaterProgressTable: React.FC<{ raterList: Array<RequiredRaterType> }> = ({ raterList }) => {
  const [searchParams] = useSearchParams();
  const surveyInstanceSecretKey = searchParams.get("k");

  return (
    <table className="min-w-full">
      <thead className="hidden sm:table-header-group bg-secondary-dark text-light">
        <tr>
          <th scope="col" className="py-3.5 text-left font-semibold text-gray-900 ps-3">
            Rater
          </th>
          <th scope="col" className="py-3.5 text-left font-semibold text-gray-900 ps-3">
            Progress
          </th>
          <th scope="col" className="py-3.5 text-center font-semibold text-gray-900">
            Actions
          </th>
        </tr>
      </thead>

      <tbody className="divide-y divide-secondary-border bg-white">
        {raterList.map((rater) =>
          rater.raterSurveyInstance ? (
            // Nominated Rater
            <tr className="even:bg-secondary-light flex flex-col sm:table-row" key={rater.id}>
              <td className="py-2 px-3">
                <div className="font-medium text-dark me-2">
                  {rater.raterSurveyInstance.forename} {rater.raterSurveyInstance.surname}
                </div>
                <div className="text-sm text-secondary me-2 first-letter:capitalize">{rater.label}</div>
              </td>

              <td className="pt-3 sm:pt-0 ps-2 pe-3">
                <Badge className="text-nowrap" size="sm" status={getStatusColor(rater)}>
                  &nbsp;{getStatusLabel(rater)}&nbsp;
                </Badge>
              </td>

              <td className="relative whitespace-nowrap p-2 pr-3 sm:text-right">
                {rater.raterSurveyInstance.surveyStatus !== "COMPLETE" &&
                  rater.raterSurveyInstance.deliveryStatus === "DELIVERY" && (
                    <Link
                      to={{
                        pathname: "/send-reminder",
                        search: "?k=" + surveyInstanceSecretKey + "&s=" + rater.raterSurveyInstance.surveyInstanceUuid,
                      }}
                      className="btn btn-danger text-xs w-full sm:w-36"
                    >
                      Send reminder &gt;&gt;
                    </Link>
                  )}
              </td>
            </tr>
          ) : (
            // Required Rater
            <tr className="even:bg-secondary-light flex flex-col sm:table-row" key={rater.id}>
              <td className="py-2 px-3">
                <span className="font-medium text-dark me-2 inline-block first-letter:capitalize">{rater.label}</span>
                {rater.isRequired && <Badge status="danger">Required</Badge>}
              </td>
              <td></td>
              <td className="relative whitespace-nowrap p-2 pr-3 text-right">
                <Link
                  to={{
                    pathname: "/nominate-rater",
                    search: "?k=" + surveyInstanceSecretKey + "&t=" + rater.raterType,
                  }}
                  className="btn btn-primary text-xs w-full sm:w-36"
                >
                  Nominate &gt;&gt;
                </Link>
              </td>
            </tr>
          ),
        )}
      </tbody>
    </table>
  );
};

/*
 * Private helper functions
 */

const getStatusColor = (rater: RequiredRaterType) => {
  const surveyStatusColor: Record<SurveyStatus, StatusColor> = {
    COMPLETE: "success",
    CREATED: "secondary",
    NO_RESPONSE: "secondary",
    PARTIALLY_COMPLETE: "primary",
    VISITED: "secondary",
  };

  if (!rater.raterSurveyInstance?.deliveryStatus) return "secondary";
  if (["BOUNCE_PERMANENT", "BOUNCE_TRANSIENT"].includes(rater.raterSurveyInstance?.deliveryStatus)) return "danger";
  return surveyStatusColor[rater.raterSurveyInstance.surveyStatus];
};

const getStatusLabel = (rater: RequiredRaterType) => {
  const surveyStatusLabels: Record<SurveyStatus, string> = {
    COMPLETE: "Complete",
    CREATED: "No response",
    NO_RESPONSE: "No response",
    PARTIALLY_COMPLETE: "In progress",
    VISITED: "No response",
  };

  if (!rater.raterSurveyInstance?.deliveryStatus) return "Scheduled";
  if (["BOUNCE_PERMANENT", "BOUNCE_TRANSIENT"].includes(rater.raterSurveyInstance?.deliveryStatus))
    return "Email delivery failed";
  return surveyStatusLabels[rater.raterSurveyInstance.surveyStatus];
};

export default RaterProgressTable;
