import { SurveyPage } from "./entity";
import { applyToAllItems } from "./apply-to-all-survey-items";

export const resolveSurveyDefinitionTemplateValues = (
  surveyDefinition: Array<SurveyPage>,
  model: Record<string, unknown>,
): Array<SurveyPage> => {
  applyToAllItems((item) => {
    if ("html" in item) item.html = resolveTemplateValues(item.html, model);
    if ("label" in item && item.label) item.label = resolveTemplateValues(item.label, model);

    if ("titleMap" in item) {
      item.titleMap.forEach((titleMap) => {
        titleMap.label = resolveTemplateValues(titleMap.label, model);
      });
    }
  }, surveyDefinition);
  return surveyDefinition;
};

export const resolveTemplateValues = (str: string, model: Record<string, unknown>) => {
  // Replace all instances of ${key} with the value of model[key]
  return str.replace(/\${([^}]+)}/g, (_, key) => {
    // The key may contain a period, which means that we look up values from the model recursively
    return key.split(".").reduce((acc: Record<string, unknown>, key: string) => acc[key], model);
  });
};
