import React, { useContext, useState } from "react";
import { RespondentHomepageContext } from "../../providers";
import { Link, useNavigate, useSearchParams } from "react-router";
import { emailRegex } from "@careerinsight/applib-common";
import { AlertBox, getErrorMessage } from "@careerinsight/applib-react";

type FormData = {
  forename: string;
  surname: string;
  email: string;
};

export const NominateRater: React.FC = ({}) => {
  const context = useContext(RespondentHomepageContext);
  const [searchParams] = useSearchParams();

  const [formData, setFormData] = useState<FormData>({ forename: "", surname: "", email: "" });
  const [validationErrors, setValidationErrors] = useState<FormData>({ forename: "", surname: "", email: "" });
  const [submitted, setSubmitted] = useState(false);
  const [serverErrorMessage, setServerErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const model = context.model!;
  const surveyInstanceSecretKey = searchParams.get("k")!;
  const raterType = searchParams.get("t");
  if (!raterType) return <pre>Rater type parameter is missing</pre>;

  const rater = model.requiredRaters!.find((r) => r.raterType === raterType);
  if (!rater) return <pre>Rater type '{raterType}' not found</pre>;

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const { name, value } = event.target;
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);
    if (submitted) validate(newFormData);
  };

  const validate = (formData: FormData) => {
    const newErrors = { forename: "", surname: "", email: "" };
    if (!formData.forename) newErrors.forename = "A value is required";
    if (!formData.surname) newErrors.surname = "A value is required";

    if (!formData.email) newErrors.email = "A value is required";
    else if (!emailRegex.test(formData.email)) newErrors.email = "Invalid email address";

    setValidationErrors(newErrors);
    return newErrors;
  };

  const onSubmit: React.FormEventHandler<HTMLFormElement> = async (event) => {
    setSubmitted(true);
    event.preventDefault();
    const errors = validate(formData);
    if (errors.forename || errors.surname || errors.email) return;

    setIsLoading(true);
    const response = await context.nominateRater({ ...formData, raterType });

    if (response.status !== 200) {
      const content = await response.json();
      setServerErrorMessage(getErrorMessage(content));
      setIsLoading(false);
    } else {
      await navigate("/homepage?k=" + surveyInstanceSecretKey);
      setIsLoading(false);
    }
  };

  return (
    <>
      <h1 className="text-3xl font-bold">
        Nominate: <span className="inline-block first-letter:capitalize">{rater.label}</span>
      </h1>
      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dolor dolore omnis voluptatibus.</p>
      {serverErrorMessage && <AlertBox>{serverErrorMessage}</AlertBox>}

      <form
        className="bg-white ring-1 shadow-xs ring-secondary-border rounded-lg md:col-span-2"
        onSubmit={onSubmit}
        noValidate={true}
      >
        <div className="px-4 py-6 sm:p-8">
          <div className="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="forename" className="block text-sm/6 font-medium text-dark">
                First name
              </label>

              <div className="mt-2">
                <input
                  type="text"
                  name="forename"
                  id="forename"
                  className={`block w-full rounded-md bg-white px-3 py-1.5 border focus:outline-2 focus:outline-primary disabled:bg-secondary-light disabled:text-secondary ${
                    validationErrors.forename
                      ? "border-danger text-danger"
                      : "bg-secondary-light/20 border-secondary-border"
                  }`}
                  value={formData.forename}
                  onChange={onChange}
                  disabled={isLoading}
                />
                {validationErrors.forename && (
                  <small className="text-danger font-semibold">{validationErrors.forename}</small>
                )}
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="surname" className="block text-sm/6 font-medium text-dark">
                Last name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="surname"
                  id="surname"
                  className={`block w-full rounded-md bg-white px-3 py-1.5 border focus:outline-2 focus:outline-primary disabled:bg-secondary-light disabled:text-secondary ${
                    validationErrors.surname
                      ? "border-danger text-danger"
                      : "bg-secondary-light/20 border-secondary-border"
                  }`}
                  value={formData.surname}
                  onChange={onChange}
                  disabled={isLoading}
                />
                {validationErrors.surname && (
                  <small className="text-danger font-semibold">{validationErrors.surname}</small>
                )}{" "}
              </div>
            </div>

            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm/6 font-medium text-gray-900">
                Email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  className={`block w-full rounded-md bg-white px-3 py-1.5 border focus:outline-2 focus:outline-primary disabled:bg-secondary-light disabled:text-secondary ${
                    validationErrors.email
                      ? "border-danger text-danger"
                      : "bg-secondary-light/20 border-secondary-border"
                  }`}
                  value={formData.email}
                  onChange={onChange}
                  disabled={isLoading}
                />
                {validationErrors.email && (
                  <small className="text-danger font-semibold">{validationErrors.email}</small>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center gap-x-6 border-t border-secondary-border px-4 py-4 sm:px-8">
          <button type="submit" className="btn btn-primary relative" disabled={isLoading}>
            <div className={`w-full absolute left-0 flex justify-center ${isLoading ? "visible" : "invisible"}`}>
              <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" className="h-6 animate-spin">
                <g fill="currentColor">
                  <circle cx="12" cy="2.5" r="1.5" opacity=".14" />
                  <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
                  <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
                  <circle cx="21.50" cy="12.00" r="1.5" opacity=".57" />
                  <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
                  <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
                  <circle cx="12" cy="21.5" r="1.5" />
                </g>
              </svg>
            </div>

            <div className={`flex items-center gap-2 ${isLoading ? "invisible" : "visible"}`}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 2 28 28" width={22} height={22}>
                <path
                  fill="currentColor"
                  d="M 3.59375 5.34375 L 4.03125 7.21875 L 5.96875 16 L 4.03125 24.78125 L 3.59375 26.65625 L 5.375 25.9375 L 27.375 16.9375 L 29.65625 16 L 27.375 15.0625 L 5.375 6.0625 L 3.59375 5.34375 z M 6.375 8.65625 L 21.90625 15 L 7.78125 15 L 6.375 8.65625 z M 7.78125 17 L 21.90625 17 L 6.375 23.34375 L 7.78125 17 z"
                ></path>
              </svg>

              <div>Nominate</div>
            </div>
          </button>

          <Link
            to={{ pathname: "/homepage", search: "?k=" + surveyInstanceSecretKey }}
            className={`btn btn-secondary ${isLoading ? "disabled" : ""}`}
          >
            Cancel
          </Link>
        </div>
      </form>
    </>
  );
};

export default NominateRater;
