import { InfoCard } from "../InfoCard";
import React, { useEffect, useState } from "react";
import { RespondentHomepageResponse } from "@careerinsight/applib-common/entity";
import { RaterProgressTable } from "../RaterProgressTable";
import { RequiredRaterType } from "../../types";

export const RaterProgressCard: React.FC<{ model: RespondentHomepageResponse }> = ({ model }) => {
  const [raterList, setRaterList] = useState<Array<RequiredRaterType>>([]);

  const [raterCounts, setRaterCounts] = useState({
    totalMinimumRequiredRaterCount: 0,
    totalMaximumRequiredRaterCount: 0,
    totalRaterCount: 0,
    outstandingRaterCount: 0,
    maxAdditionalRaterCount: 0,
  });

  useEffect(() => setRaterList(compileRaterList(model)), [model]);
  useEffect(() => setRaterCounts(calculateRaterCounts(model, raterList)), [raterList]);

  return (
    <InfoCard>
      {/* Status */}
      <div className="font-semibold">
        <h3 className="text-xl">Your 360&deg; feedback raters</h3>

        {raterCounts.outstandingRaterCount > 0 && (
          <p className="mt-4">
            {raterCounts.totalRaterCount > 0 ? (
              <>
                <span>
                  You've nominated {raterCounts.totalRaterCount} rater{raterCounts.totalRaterCount !== 1 && "s"} -
                  please nominate at least {raterCounts.outstandingRaterCount} more rater
                  {raterCounts.outstandingRaterCount !== 1 && "s"}.
                </span>

                <span> </span>
              </>
            ) : (
              <span>
                Please nominate at least {raterCounts.outstandingRaterCount} rater
                {raterCounts.outstandingRaterCount !== 1 && "s"}
              </span>
            )}
          </p>
        )}

        {raterCounts.outstandingRaterCount <= 0 && raterCounts.maxAdditionalRaterCount > 0 && (
          <p className="mt-4">Thank you - you have nominated sufficient raters, but you can add more if you like.</p>
        )}

        {raterCounts.outstandingRaterCount <= 0 && raterCounts.maxAdditionalRaterCount <= 0 && (
          <p className="mt-4">Thank you - you have nominated the maximum number of raters.</p>
        )}
      </div>

      {/* General blurb */}
      <div className="my-4">
        <p className="mb-3">
          You should nominate people who know you well enough to give an informed view of how you operate.
        </p>

        <p className="mb-3">
          We recommend that you nominate a good number of colleagues who know you well in case some are not able or do
          not wish to provide you with feedback. We suggest a maximum of 10 in each of the categories.
        </p>

        <p className="mb-3">All responses will be anonymous apart from those from your line manager.</p>

        <p className="mb-3">
          If you only nominate 1 Rater per category then their responses will be combined with other categories to
          preserve anonymity. From the homepage, you have the opportunity to return to this page to add more Raters
          should you need to.
        </p>
      </div>

      {/* Table */}
      <div className="overflow-hidden shadow ring-1 ring-dark rounded-lg">
        <RaterProgressTable raterList={raterList} />
      </div>
    </InfoCard>
  );
};

/*
 * Private helper functions
 */

/**
 * Returns an object containing various rater counts
 */
const calculateRaterCounts = (model: RespondentHomepageResponse, raterList: Array<RequiredRaterType>) => {
  let totalMinimumRequiredRaterCount = 0;
  let totalMaximumRequiredRaterCount = 0;

  for (const rr of model.requiredRaters!) {
    totalMinimumRequiredRaterCount += rr.minCount;
    totalMaximumRequiredRaterCount += rr.maxCount;
  }

  const totalRaterCount = model.raterSurveyInstances!.length;
  const outstandingRaterCount = raterList.filter((r) => r.isRequired && !r.raterSurveyInstance).length;

  return {
    totalMinimumRequiredRaterCount,
    totalMaximumRequiredRaterCount,
    totalRaterCount: model.raterSurveyInstances!.length,
    outstandingRaterCount,
    maxAdditionalRaterCount: totalMaximumRequiredRaterCount - totalRaterCount,
  };
};

/**
 * Compiles the list of raters to be shown on-screen
 */
const compileRaterList = (model: RespondentHomepageResponse) => {
  const list: Array<RequiredRaterType> = [];
  const remainingRaterSurveyInstances = [...model.raterSurveyInstances!];

  // Create a completely "unrolled" list of all required raters, including populated ones
  model.requiredRaters!.forEach((rr) => {
    for (let i = 0; i < rr.maxCount; i++) {
      const item: RequiredRaterType = {
        id: rr.raterType + "-" + i,
        label: rr.label,
        raterType: rr.raterType,
        isRequired: i < rr.minCount,
      };

      const matchingRaterSurveyInstance = remainingRaterSurveyInstances.find((s) => s.raterType === rr.raterType);
      if (matchingRaterSurveyInstance) {
        item.raterSurveyInstance = matchingRaterSurveyInstance;
        remainingRaterSurveyInstances.splice(remainingRaterSurveyInstances.indexOf(matchingRaterSurveyInstance), 1);
      }

      list.push(item);
    }
  });

  // Remove any duplicate non-required raters
  let previous: RequiredRaterType | undefined = undefined;
  const newList: Array<RequiredRaterType> = [];

  for (const rr of list) {
    // Keep the first item of the list
    if (!previous) newList.push(rr);
    // If the rater is required then keep it
    else if (rr.isRequired) newList.push(rr);
    // If the rater type is different from the previous one then keep it
    else if (rr.raterType !== previous.raterType) newList.push(rr);
    // If the rater is populated then keep it
    else if (rr.raterSurveyInstance) newList.push(rr);
    // If the previous rater is populated, but this one isn't then keep it
    else if (previous.raterSurveyInstance && !rr.raterSurveyInstance) newList.push(rr);
    previous = rr;
  }

  return newList;
};

export default RaterProgressCard;
