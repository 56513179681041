import { NominatedRater } from "@careerinsight/applib-common/zod";
import { appSettings } from "@careerinsight/applib-react";

export const sendReminderMessage = async (
  surveyInstanceSecretKey: string,
  props: {
    message: string;
    raterSurveyUuid: string;
  },
) => {
  const url = appSettings.surveyApi + "/surveys/" + surveyInstanceSecretKey + "/send-reminder-message";

  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(props),
  });
};

export const submitNominatedRater = async (surveyInstanceSecretKey: string, nominatedRater: NominatedRater) => {
  const url = appSettings.surveyApi + "/surveys/" + surveyInstanceSecretKey + "/nominate-rater";

  return await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(nominatedRater),
  });
};
