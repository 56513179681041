import React from "react";
import { SurveyApp, SurveyHeader, SurveyProvider, ValidationProvider } from "@careerinsight/applib-react";
import { BrowserRouter, Route, Routes } from "react-router";
import { RespondentHomepageProvider } from "../../providers";
import Homepage from "../Homepage";
import HomepageWrapper from "../HomepageWrapper";
import { NominateRater } from "../NominateRater";
import { SendReminder } from "../SendReminder";

function App() {
  const surveyApp = (
    <SurveyProvider>
      <ValidationProvider>
        <SurveyApp>
          <SurveyHeader />
        </SurveyApp>
      </ValidationProvider>
    </SurveyProvider>
  );

  const respondentHomepage = (
    <RespondentHomepageProvider>
      <HomepageWrapper>
        <Homepage />
      </HomepageWrapper>
    </RespondentHomepageProvider>
  );

  const nominateRater = (
    <RespondentHomepageProvider>
      <HomepageWrapper>
        <NominateRater />
      </HomepageWrapper>
    </RespondentHomepageProvider>
  );

  const sendReminder = (
    <RespondentHomepageProvider>
      <HomepageWrapper>
        <SendReminder />
      </HomepageWrapper>
    </RespondentHomepageProvider>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={surveyApp} />
        <Route path="homepage" element={respondentHomepage} />
        <Route path="nominate-rater" element={nominateRater} />
        <Route path="send-reminder" element={sendReminder} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
